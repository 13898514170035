import React from 'react';

const People = () => {
  return (
    <>
      <div className='row'>
        <div className='col-sm-12 col-md-6'>
          <div className='row'>
            <div className='col-12 text-center'>
              <img
                src='/assets/images/saju.jpeg'
                alt='Saju Sudhakaran'
                className='img-fluid img-circle'
              />
            </div>
            <div className='col-12 text-center'>
              <p className='lead'>Saju Sudhakaran</p>
              <p>Founder / CEO</p>
              <p>
                <a
                  href='https://www.linkedin.com/in/saju-sudhakaran-39763016/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='btn btn-link'
                >
                  <h3 className='bi bi-linkedin'></h3>
                </a>
              </p>
              {/* <p className='justify-text'>
                Saju Sudhakaran is a seasoned leader with over two decades of
                experience in the field of information technology. As the former
                Director of Operations at OnMobile Global, he demonstrated
                exceptional skills in operational management, service delivery,
                and stakeholder engagement. Armed with a Bachelor of Engineering
                in Electronics and Communications and an Executive Post Graduate
                Diploma in Lean Operations Management, Saju has a solid
                foundation in both technical expertise and strategic management.
                His passion for excellence drives him to continuously strive for
                efficiency, reliability, and scalability in all his endeavors.
                With a track record of spearheading successful initiatives and
                leading dedicated teams, Saju Sudhakaran is known for his
                commitment to driving organizational success and making a
                tangible difference in the ever-evolving landscape of
                technology.
              </p> */}
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-md-6'>
          <div className='row'>
            <div className='col-12 text-center'>
              <img
                src='/assets/images/vinod.jpeg'
                alt='Vinod Kumar Kayartaya'
                className='img-fluid img-circle'
              />
            </div>
            <div className='col-12 text-center'>
              <p className='lead'>Vinod Kumar Kayartaya</p>
              <p>Founder / CTO</p>
              <p>
                <a
                  href='http://www.linkedin.com/in/javatrainer/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='btn btn-link'
                >
                  <h3 className='bi bi-linkedin'></h3>
                </a>
              </p>
              {/* <p className='justify-text'>
                Vinod Kumar Kayartaya is a multifaceted professional excelling
                as an evangelist, consultant, and developer in the tech
                industry. His journey began with a strong educational
                foundation, earning a University Rank from Kuvempu University
                and furthering his studies in Computer Science. Transitioning
                from teaching computer software to software development and
                consulting, Vinod has left a mark in big companies like Infosys,
                HP, and Cap Gemini since 2003. His expertise spans across a
                diverse range of technologies, showcased through his work with
                renowned clients like ABB, Accenture, Cisco and many more.
                Notably, Vinod is also a respected Udemy and SkillShare
                instructor, offering popular courses. Beyond teaching and
                consulting, he actively contributes to open-source projects on
                GitHub, solidifying his presence as a prominent figure in the
                tech community. Explore more about him on his Udemy profile or
                personal website (https://vinod.co) for a glimpse into his
                prolific career.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default People;
