import People from './components/People';
import Header from './components/Header';
import Hero from './components/Hero';
import Products from './components/Products';
import Services from './components/Services';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Header />
      <div className='container'>
        <Hero />
        <hr />
        <Services />
        <hr />
        <Products />
        <hr />
        <People />
        <hr />
        <Footer />
      </div>
    </>
  );
}

export default App;
