import React from 'react';

const Header = () => {
  return (
    <>
      <div className='alert alert-primary1'>
        <div className='container text-center'>
          <h1>
            <img
              src='/assets/images/cyblore-logo.svg'
              alt='cyblore'
              style={{ maxHeight: '75px' }}
            />
            <span className='cb-purple'>cy</span>
            <span className='cb-blue'>blore</span>
          </h1>
          <hr />
        </div>
      </div>
    </>
  );
};

export default Header;
