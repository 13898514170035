import React from 'react';

const Services = () => {
  return (
    <>
      <div className='mb-4 rounded-3  d-none d-md-block'>
        <div className='container-fluid py-5'>
          <div className='row'>
            <div className='col-md-8 col-12 fs-4'>
              <h1 className='display-5 fw-bold'>Services</h1>
              <h2>Desktop &middot; Web &middot; Mobile &middot; Cloud</h2>
              <p className='lead mt-5'>
                We craft custom software solutions to fit your specific needs,
                regardless of platform. Our expertise spans desktop applications
                for streamlined workflows, user-friendly web applications
                accessible from any device, engaging mobile applications for
                on-the-go functionality, and secure cloud-based applications for
                scalable and collaborative solutions. Let us help you choose the
                perfect platform to maximize the impact of your software.
              </p>
            </div>

            <div className='col-md-4 col-12 fs-4'>
              <img
                src='/assets/images/5071700.jpg'
                alt=''
                className='img-fluid'
                style={{ maxHeight: '500px' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='mb-4 rounded-3  d-blick d-md-none'>
        <div className='container-fluid py-5'>
          <div className='row'>
            <div className='col-md-4 col-12 fs-4'>
              <img
                src='/assets/images/5071700.jpg'
                alt=''
                className='img-fluid'
                style={{ maxHeight: '500px' }}
              />
            </div>

            <div className='col-md-8 col-12 fs-4'>
              <h1 className='display-5 fw-bold'>Services</h1>
              <h2>Desktop &middot; Web &middot; Mobile &middot; Cloud</h2>
              <p className='lead mt-5 justify-text'>
                We craft custom software solutions to fit your specific needs,
                regardless of platform. Our expertise spans desktop applications
                for streamlined workflows, user-friendly web applications
                accessible from any device, engaging mobile applications for
                on-the-go functionality, and secure cloud-based applications for
                scalable and collaborative solutions. Let us help you choose the
                perfect platform to maximize the impact of your software.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
