import React from 'react';

const Hero = () => {
  return (
    <>
      <div className='mb-4 rounded-3'>
        <div className='container-fluid py-5'>
          <div className='row'>
            <div className='col-md-4 fs-4'>
              <img
                src='/assets/images/9848264.jpg'
                alt=''
                className='img-fluid'
                style={{ maxHeight: '500px' }}
              />
            </div>
            <div className='col-md-8 fs-4'>
              <h1 className='display-5 fw-bold'>
                Building secure, high performance Apps
              </h1>
              <h2>Delivered with quality</h2>
              <p className='lead mt-5 justify-text'>
                We believe exceptional software shouldn't compromise. At{' '}
                <span className='cb-purple'>cy</span>
                <span className='cb-blue'>blore</span>, we're dedicated to
                building secure, high-performance applications that deliver
                unparalleled quality. Our development process prioritizes robust
                security measures to protect your data, while optimizing code
                for peak performance and a seamless user experience. We deliver
                only the highest quality applications, ensuring they meet your
                specific needs and provide a lasting advantage.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
