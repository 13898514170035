import React from 'react';

const Products = () => {
  return (
    <>
      <div className='mb-4 rounded-3'>
        <div className='container-fluid py-5'>
          <div className='row'>
            <div className='col-md-4 fs-4 mb-5'>
              <img
                src='/assets/images/8257712.jpg'
                alt=''
                className='img-fluid'
                style={{ maxHeight: '500px' }}
              />
            </div>
            <div className='col-md-8 fs-4'>
              <h1 className='display-5 fw-bold'>
                VidyaSampark<sup>&reg;</sup>
              </h1>
              <h2>
                Bringing students, teachers and parents closer
              </h2>
              <p className='lead mt-5 justify-text'>
                We're developing a user-friendly school-parent management
                application designed to enhance communication and engagement.
                Recognizing the existing solutions in the market, our focus is
                on providing a streamlined and intuitive experience for both
                parents and students. The app offers easy access to essential
                information like student progress reports, attendance records,
                and fee details. It also allows parents to view assignments and
                track submissions, fostering collaboration between home and
                school. With a focus on clarity and ease of use, our app aims to
                be a valuable tool for supporting student success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
