import React from 'react';

const Footer = () => {
  return (
    <>
      <div className='row'>
        <div className='col-md-4 col-sm-12'>
          {/* <img
            src='/assets/images/cyblore.png'
            alt='cyblore'
            className='img-fluid'
          /> */}
          <p className='lead'>CyBlore IT Solutions Pvt Ltd</p>
        </div>
        <div className='col-md-4 col-sm-12'>
          <p className='lead'>Address: </p>
          <p>
            #803, Indigo block, HM World City <br />
            JP Nagar 8th Phase <br />
            Bangalore &middot; Karnataka - 560108 <br />
            India / Bharat
          </p>
        </div>
        <div className='col-md-4 col-sm-12'>
          <p className='lead'>Contact:</p>
          <p>
            <i className='bi bi-envelope'></i>{' '}
            <a href='mailto:saju.sudhakaran@cyblore.com'>
              saju.sudhakaran@cyblore.com
            </a>
          </p>
          <p>
            <i className='bi bi-telephone'></i> +91 99725 66070
          </p>
        </div>
      </div>
      <div className='text-center mt-5 mb-5'>
        &copy; 2024 &middot; All rights reserved.
      </div>
    </>
  );
};

export default Footer;
